import React, { createContext } from 'react';
import { Routes, Route } from "react-router-dom";
import { createTheme, Stack, useMediaQuery,} from '@mui/material';
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';
import TitleBanner from '../components/Main/HeadBanner';
import Header from '../components/Main/Header';
import About from '../components/Main/About';
import WhatsCF from '../components/Main/WhatsCF';
import Merit from '../components/Main/Merit';
import Service from '../components/Main/Service';
import Flow from '../components/Main/Flow';
import Amount from '../components/Main/Amount';
import Works from '../components/Main/Works';
import Contact from '../components/Main/Contact';
import Footer from '../components/Main/Footer';
import ContactBanner from '../components/Main/ContactBanner';

// ブレークポイント定義
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1000,  
      lg: 1300,
      xl: 1500,
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans JP', // ヒラギノフォントを指定
      'Arial', // 代替フォント
      'sans-serif', // サンセリフフォント
    ].join(','),
  },
});


// Socketオブジェクトとユーザーオブジェクトの型を定義
interface Context {
  titleFontSize: number;
  subTitleFontSize: number;
  columnFontSize: number;
  mainFontSize: number;
  subFontSize: number;
  headerSize: number;

  contentRatio:number;

  isXS: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
}


// Contextの初期値を設定する
export const MyContext = createContext<Context>({ 
  titleFontSize:0, subTitleFontSize:0, columnFontSize:0, 
  mainFontSize: 0, subFontSize:0, headerSize:0,
  contentRatio: 0,
  isXS: false, isSmall: false, isMedium:false, isLarge:false });


const Router = () => {

  // 700px 以下
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  // 700~1000px(sm) まで
  const isSmall = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // 1000~1300px(md) まで
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  // 1300px(md) 以降
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const titleFontSize = isXS ? 24 : 
                        isSmall ? 38 : 
                        isMedium ? 48 : 
                        isLarge ? 68 : 68

  const subTitleFontSize = isXS ? 19 : 
                        isSmall ? 22 : 
                        isMedium ? 24 : 
                        isLarge ? 32 : 34

  const mainFontSize = isXS ? 12 : 
                        isSmall ? 13 : 
                        isMedium ? 14.5 : 
                        isLarge ? 16 : 0

  const contentRatio = isXS ? 0.9 : 
                      isSmall ? 0.9 : 
                      isMedium ? 0.8 : 
                      isLarge ? 0.75 : 0


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MyContext.Provider value={{ 
        titleFontSize: titleFontSize,
        subTitleFontSize: subTitleFontSize,
        columnFontSize: titleFontSize,
        mainFontSize: mainFontSize,
        subFontSize: titleFontSize,
        headerSize: isXS ? 60 : 80,
        contentRatio: contentRatio,
        isXS: isXS, isSmall:isSmall, isMedium:isMedium, isLarge: isLarge
      }}>

        {/* メインコンポーネントをここに記述 */}
        <Header/>
        <TitleBanner />
        <About/>
        <WhatsCF/>
        <Merit/>
        <Service/>
        <Flow/>
        <ContactBanner/>
        <Amount/>
        <Works/>
        <Contact/>
        <Footer isMobile={false}/>

      </MyContext.Provider>
    </ThemeProvider>
  );
};

export default Router;