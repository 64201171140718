import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import service1Image from  '../../../img/service1.png'
import backgroundImage from  '../../../img/background.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"
import ServiceCard from '../../Card/serviceCard';


// テキストの設定
const title : string = "STEP1"
const subTitle : string = "「無料ご相談」"
const description : string = "クラウドファンディングにおける掲載文は、プロジェクトの第一印象や信頼性、プロジェクトの魅力を効果的に伝え、支援者とのコミュニケーションを促進する上で非常に重要です。"


const Service: React.FC = () => {

    const { titleFontSize, subTitleFontSize, headerSize, mainFontSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);
    const services = [
        {
          title: '業界最安値',
          img:service1Image,
          subTitle: '良心的な価格設定',
          description: isSmall ? '弊社は事業者様が最大の利益を得られる料金プランを提供しています。'
                            : '弊社は事業者様が最大の利益を得られる料金プランを提供しています。'
        },
        {
          title: '安心の英語力',
          img:service1Image,
          subTitle: '多彩な海外実績',
          description: isSmall ? '帰国子女や海外事業経験者など、高度な英語能力を有するスタッフが担当しております。'
                            : '帰国子女や海外事業経験者など、高度な英語能力を有するスタッフが担当しております。'
        },
        {
          title: '事例分析',
          img:service1Image,
          subTitle: '海外市場を熟知',
          description: isSmall ? '海外クラファンの商品を徹底的に分析し、その知識を元に出品する商品の選定を行っています。'
                            : '海外クラファンの商品を徹底的に分析し、その知識を元に出品する商品の選定を行っています。'
        },
        {
          title: '海外配送代行',
          img:service1Image,
          subTitle: '海外配送も完全サポート',
          description: isSmall ? '英語での手続きやトラブルが多い海外配送も、弊社が一括して代行いたします。'
                            : '英語での手続きやトラブルが多い海外配送も、弊社が一括して代行いたします。'
        }
      ];


    const componentHeight = isXS ? 240 :
                            isSmall ? 300 :
                            isMedium ? 430 : 
                            isLarge ? 480 : 0

    const _isMobile = isXS || isSmall;
    const [isViewed, setIsViewed] = useState<boolean>(false)


    return (
        <Stack id={"service"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.3, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='私たちの強み' title_en='SERVICE' fontSize={subTitleFontSize+5}/>
            <Stack mt={4} width={isSmall ?  "75%" : contentRatio*100+"%"} justifyContent={"center"} zIndex={2} >
                <ObserverStack isOneTime={true} setObserved={setIsViewed}>
                    <BaseGrid animationOffsetY={0} enableAnimation={isViewed} cols={_isMobile ? 2 : 4} spacingX={_isMobile ? 2 : 1} spacingY={2} items={
                        services.map((value:any, index:number) => (
                            <ServiceCard spacing={_isMobile ? 1 : 2} img_ratio={_isMobile ? "40%" : "80%"} height={componentHeight} image={value.img} title={value.title} subTitle={value.subTitle} description={value.description} titleFontSize={subTitleFontSize} contentFontSize={mainFontSize} />
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default Service;