import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import titleBannerImage from  '../../../img/titleBanner.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid} from "reidea-common"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// テキストの設定
const title : string = "海外クラファン\n0円から一括代行"
const subTitle : string = "RE-IDEAはあなたの技術を\n世界に届ける会社です"
const description : string = "RE-IDEAはあなたの技術を\n世界に届けるお手伝いをする会社です"


const Amount: React.FC = () => {

    const { titleFontSize, subTitleFontSize, mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const [cellWidth, setCellWidth] = useState<number>(Math.max(window.innerWidth * contentRatio / 5, 120));
    const cellHeight = 75;
    // 初期費用 のセルだけちょい高くする分
    const offsetHeight = 20;

    const twoRowsCellComponent = (text1:string, text2:string, fontSizeRatio?: number, offsetHeight?:number) => {
        return(
            <Stack width={cellWidth} height={cellHeight+(offsetHeight ?? 0)} justifyContent={"center"} alignItems={"center"}>
                <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize+12}>{text1}</Typography>
                <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize-2}>{text2}</Typography>
            </Stack>
        )
    } 


    const normalCellComponent = (text:string, fontSizeRatio?: number, offsetHeight?:number) => {
        return(
            <Typography width={cellWidth} fontWeight={"bold"} height={cellHeight} textAlign={"center"} alignItems={"center"} color={"#383E86"} fontSize={(mainFontSize+12)* (fontSizeRatio ?? 1)} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>{text}</Typography>
        )
    } 

    useEffect(() => {
        const handleResize = () => {
            setCellWidth(window.innerWidth * contentRatio / 5);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isSmall, isLarge, isMedium, isXS]); 

    const _isMobile = isXS || isSmall;


    return (
        <Stack id={"amount"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <TitleColumn title='料金表' title_en='AMOUNT' fontSize={subTitleFontSize+5}/>
            <Stack mt={4} width={100*contentRatio+"%"} justifyContent={"center"} sx={{overflowX:"auto"}}>
                {/* ヘッダー */}
                <Stack direction={"row"} width={cellWidth*5}>
                    <Box width={cellWidth} height={cellHeight} bgcolor={"whtie"}/>
                    <Stack direction={"row"} width={cellWidth*4} alignItems={"center"} height={cellHeight} sx={{background:'linear-gradient(to right, #3cb9a0, #164e8d)'}}>
                        {["ライトプラン", "ベーシックプラン", "スタンダードプラン", "プレミアムプラン"].map((value: string, index:number) => (
                            <>
                                <Typography width={cellWidth} textAlign={"center"} color={"white"} fontWeight={600} fontSize={mainFontSize+2} letterSpacing={1.2}sx={{
                                    
                                }} >
                                    {value}
                                </Typography>
                                {index !== 3 &&<Divider orientation='vertical' sx={{backgroundColor:"white", width:"0.7px", height:"60%"}} />}
                            </>
                        ))}
                    </Stack>
                </Stack>

                <Stack direction={"row"}>
                    {/* 列ヘッダー */}
                    <Stack direction={"column"} width={cellWidth} justifyContent={"center"} alignItems={"center"} height={cellHeight*8+offsetHeight} sx={{background:'linear-gradient(to bottom, #3cb9a0, #164e8d)'}}>
                        {["初期費用", "手数料", "プロジェクト\n申請", "運営・支援者\nとのやり取り", "海外配送代行", "プロジェクト\nページ制作", "画像動画\n撮影・編集"].map((value: string, index:number) => (
                            <>
                                <Stack justifyContent={"center"} width={cellWidth} height={cellHeight+(index === 0 ? 20 : 0)} textAlign={"center"} color={"white"} fontWeight={800} fontSize={mainFontSize+2} letterSpacing={1.2}sx={{
                                    whiteSpace:"pre-wrap"
                                }} >
                                    {value}
                                </Stack>
                                {<Divider sx={{backgroundColor:"white", width:"70%", }} />}
                            </>
                        ))}
                        <Stack justifyContent={"center"} width={cellWidth} height={cellHeight} textAlign={"center"} color={"white"} fontWeight={800} fontSize={mainFontSize+2} letterSpacing={1.2}sx={{
                            whiteSpace:"pre-wrap"
                        }} >
                            {"海外向け広告"}
                            <Typography fontSize={mainFontSize-4}>※別途オプション</Typography>
                        </Stack>
                    </Stack>

                    {/* 中身 */}
                    <Stack bgcolor={"#F5FBFE"} direction={"column"} width={cellWidth*4} alignItems={"center"} height={cellHeight*8+offsetHeight}>
                        <BaseGrid cols={4} spacingY={0} items={
                            [
                                normalCellComponent("¥ 0", 1, offsetHeight), twoRowsCellComponent("¥ 98,000", "(税込107,800円)", 1, offsetHeight), twoRowsCellComponent("¥ 198,000", "(税込217,800円)", 1, offsetHeight), twoRowsCellComponent("¥ 298,000", "(税込327,800円)", 1, offsetHeight),
                                normalCellComponent("20%"), normalCellComponent("20%"), normalCellComponent("20%"), normalCellComponent("10%"), 
                                normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6),
                                normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6),
                                normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6),
                                normalCellComponent("×", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6),
                                normalCellComponent("×", 1.6), normalCellComponent("×", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6),
                                normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6),
                            ]
                        }></BaseGrid>
                    </Stack>
                    
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Amount;