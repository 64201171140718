import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import worldImage from  '../../../img/world.png'
import whatsCFImage from  '../../../img/whatsCF.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid} from "reidea-common"

// テキストの設定
const title : string = "海外クラウドファンディングとは"
const subTitle : string = "あらかじめ売れる数を把握してからの海外進出"
const description : string = "海外クラウドファンディングは、受注生産型であなたの商品を販売することができるため、在庫を抱える心配がなく、低リスクで海外進出へ挑戦することができます。\nまた実際の海外市場での販売になるため、リスクを限りなく低くした上で、より確実なテストマーケティングや、販路拡大ができることも特徴の一つです。\n弊社では、日本の事業者様に対して、この海外クラウドファンディングへの挑戦に関わる全てを一括でサポートさせていただいております。"


const ContactBanner: React.FC = () => {

    const { titleFontSize, subTitleFontSize,mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const subTitleLineHeight = 1.4
    const subTitleLetterSpacing = 3

    const fontStyle = { color:"white", whiteSpace:"pre-wrap", width:"100%",  }
    
    const _isMobile = isXS || isSmall;


    return (
        <Stack direction={"column"} width={"100%"} justifyContent={"center"} minWidth={"100%"} alignItems={"center"} spacing={4} bgcolor={"#383E86"} >

            <Stack width={"70%"} py={_isMobile ? 6 : 12} zIndex={2} spacing={2} alignItems={"center"} justifyContent={"center"}>
                <Typography sx={{...fontStyle, textAlign:"center"}} fontWeight={600} fontSize={subTitleFontSize-6} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                    {"まずは無料のご相談から、お気軽にお問い合わせください。"}
                </Typography>
                <Stack width={_isMobile ? "80%" : "50%"}>
                    <ApplyButton fontSize={subTitleFontSize-8+"px"} text='無料相談はこちら　 ▶︎'/>
                </Stack>
            </Stack>
            
        </Stack>
    );
};

export default ContactBanner;