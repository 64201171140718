import React, { useContext, useLayoutEffect, useRef, useEffect, useState, ChangeEvent} from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from  '../../../img/background.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, BaseTextField, MailTextField, PhoneTextField} from "reidea-common"
import dayjs, { Dayjs } from 'dayjs';
import axios from "axios"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import SendButton from '../../Button/sendButton';

// テキストの設定
const title : string = "海外クラファン\n0円から一括代行"
const subTitle : string = "RE-IDEAはあなたの技術を\n世界に届ける会社です"
const description : string = "RE-IDEAはあなたの技術を\n世界に届けるお手伝いをする会社です"


const Contact: React.FC = () => {

    const { titleFontSize, subTitleFontSize,mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);
    const [sei, setSei] = useState('');
    const [mei, setMei] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = React.useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [others, setOthers] = useState('');
    const [date1, setDate1] = useState<Dayjs|null>(null);
    const [date2, setDate2] = useState<Dayjs|null>(null);
    const [date3, setDate3] = useState<Dayjs|null>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setIsLoading] = useState(false);
  
  
  
    const checkFormValidity = (): void => {
      const isValid = !!(
        sei &&
        sei.trim() !== '' &&
        mei &&
        mei.trim() !== '' &&
        email &&
        email.trim() !== '' &&
        date1 !== null &&
        date2 !== null &&
        others &&
        others.trim() !== '' &&
        !(
          (date1 && date2 && date3) && 
          ((date1.isSame(date2) || date1.isSame(date3) || date2.isSame(date3)) ||
          (date1.isSame(date2, 'day') && date1.isSame(date2, 'hour') && date1.isSame(date2, 'minute')) ||
          (date1.isSame(date3, 'day') && date1.isSame(date3, 'hour') && date1.isSame(date3, 'minute')) ||
          (date2.isSame(date3, 'day') && date2.isSame(date3, 'hour') && date2.isSame(date3, 'minute')))
        )
      );
      setIsFormValid(isValid);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    
    const handleSendClick = async () => {
      try {
        setOpen(true);
        setIsLoading(true);
    
        // フォームデータをオブジェクトにまとめる
        const formData = {
          sei,
          mei,
          phoneNumber,
          email,
          others,
          date1: date1 ? date1.toISOString() : null,
          date2: date2 ? date2.toISOString() : null,
          date3: date3 ? date3.toISOString() : null,
        };
    
        // APIリクエストを送信
        const response = await axios.post("https://api.overseas-cf.com", formData);
    
        // APIリクエストが成功した後にタイムアウトを設定
        setTimeout(() => {
          setIsLoading(false);
          setSei(''); setMei("")
          setPhoneNumber(''); setEmail('');
          setOthers('');
          setDate1(null); setDate2(null); setDate3(null);
        }, 2500);
    
      } catch (err) {
        console.error("エラー:", err);
        setIsLoading(false);
        alert("フォームの送信中にエラーが発生しました。もう一度お試しください。");
      }
    };

    useEffect(() => {
      checkFormValidity();
    }, [sei, mei, email, others, date1, date2]);
  
    useEffect(() => {
      const storedValue = localStorage.getItem('editData');
  
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue);
        setSei(parsedValue.sei); setMei(parsedValue.mei);
        setPhoneNumber(parsedValue.phoneNumber);
        setEmail(parsedValue.email);
        setOthers(parsedValue.others);
        setDate1(parsedValue.date1 ? dayjs(parsedValue.date1) : null);
        setDate2(parsedValue.date2 ? dayjs(parsedValue.date2) : null);
        setDate3(parsedValue.date3 ? dayjs(parsedValue.date3) : null);
      }
    }, []);
  
    useEffect(() => {
      const editData = {
        sei: sei, mei:mei,
        phoneNumber: phoneNumber,
        email: email,
        others: others,
        date1: date1, date2: date2, date3: date3,
      };
  
      if( sei || mei ||  phoneNumber || email || others || date1 || date2 || date3) {
        localStorage.setItem('editData', JSON.stringify(editData));
      }
    }, [sei, mei,  phoneNumber, email, others, date1, date2, date3]);

    const _isMobile = isXS || isSmall;

    return (
        <Stack id={"contact"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"}  py={_isMobile ? 8 : 15}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.2, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='お問い合わせ' title_en='Contact' fontSize={subTitleFontSize+5}/>
            <Stack zIndex={2} mt={4} width={isXS ? "90%" : contentRatio*100-25+"%"} justifyContent={"center"} spacing={2}>
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"49%"}>
                        <Typography sx={{fontSize: mainFontSize, color:"#202020"}}>姓 *</Typography>
                        <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : 山田' value={sei} setValue={setSei} hovered_backgroundColor='white' backgroundColor='white' />
                    </Stack>
                    <Stack width={"49%"}>
                        <Typography sx={{fontSize: mainFontSize, color:"#202020"}}>名 *</Typography>
                        <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : 太郎' value={mei} setValue={setMei} hovered_backgroundColor='white' backgroundColor='white' />
                    </Stack>
                </Stack>

                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"49%"}>
                        <Typography sx={{fontSize: mainFontSize, color:"#202020"}}>電話番号</Typography>
                        <PhoneTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : 01234-456-789' value={phoneNumber} setValue={setPhoneNumber} hovered_backgroundColor='white' backgroundColor='white' />
                    </Stack>

                    <Stack width={"49%"}>
                        <Typography sx={{fontSize: mainFontSize, color:"#202020"}}>メールアドレス *</Typography>
                        <MailTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : abc@example.com' error={emailError} setError={setEmailError} value={email} errorText={"メールアドレスの形式が正しくありません。例 : example@domain.com"} setValue={setEmail} hovered_backgroundColor='white' backgroundColor='white' />
                    </Stack>
                </Stack>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                        <Stack>
                            <Typography sx={{fontSize: mainFontSize, color:"#202020"}}>打ち合わせ希望日時 *</Typography>
                            <Stack pt={isXS ? 2 : undefined} direction={isXS ? "column" : "row"} spacing={isXS ? 1 : undefined} justifyContent={"space-between"} >
                                <DateTimePicker 
                                  sx={{width:isXS ? "100%" : "33%"}}
                                  label="第1希望"
                                  value={date1}
                                  onChange={(newValue) => setDate1(newValue)}
                                />
                                <DateTimePicker 
                                sx={{width:isXS ? "100%" : "33%"}}
                                label="第2希望"
                                value={date2}
                                onChange={(newValue) => setDate2(newValue)}
                                />
                                <DateTimePicker 
                                sx={{width:isXS ? "100%" : "33%"}}
                                label="第3希望"
                                value={date3}
                                onChange={(newValue) => setDate3(newValue)}
                                />
                            </Stack>
                        </Stack>
                    </DemoContainer>
                </LocalizationProvider>

                <Stack>
                    <Typography sx={{fontSize: mainFontSize, color:"#202020"}}>お問い合わせ内容 *</Typography>
                    <BaseTextField border='1px solid lightgrey' borderRadius={2} rows={4} maxRows={4} placeholder='クラウドファンディングに掲載したい商品の説明を簡単にご記入ください。' value={others} setValue={setOthers} hovered_backgroundColor='white' backgroundColor='white' />
                </Stack>
            </Stack>
            <Stack pt={3} width={"30%"}>
                <SendButton
                    title='送信' 
                    isDisable={!isFormValid} 
                    open={open} 
                    onClick={handleSendClick} 
                    onClose={handleClose} 
                    isLoading={loading}
                />
            </Stack>
        </Stack>
    );
};

export default Contact;