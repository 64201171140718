import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from  '../../../img/background.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"

// テキストの設定
const title : string = "海外クラファン\n0円から一括代行"
const subTitle : string = "RE-IDEAはあなたの技術を\n世界に届ける会社です"
const description : string = "RE-IDEAはあなたの技術を\n世界に届けるお手伝いをする会社です"

const About: React.FC = () => {

    const { titleFontSize, subTitleFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const fontStyle = { color:"white", whiteSpace:"pre-wrap", width:"100%", transition: `font-size ${0.15}s ease, -webkit-text-stroke ${0.15}s ease`,}
    
    const _isMobile = (isXS || isSmall)
    const [isViewed, setIsViewed] = useState<boolean>(false)


    return (
        <Stack id={"about"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} bgcolor={"#F5FBFE"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.3, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='私たちにできること' title_en='ABOUT' fontSize={subTitleFontSize+5}/>
            <Stack width={contentRatio*100+"%"} mt={3} justifyContent={"center"} >
                <ObserverStack isOneTime={true} setObserved={setIsViewed}>
                    <BaseGrid animationInitialDisplay={false} animationOffsetY={10} height={(subTitleFontSize-4)*3} enableAnimation={isViewed} cols={_isMobile ? 2 : 3} spacingX={1} spacingY={2} items={
                        ["商品選定","掲載申請","ページ構成・翻訳","運営・運用","動画・画像制作","海外配送代行"].map((value:any, index:number) => (
                            <BaseButton text={value} height={(subTitleFontSize-4)*3+'px'} fontSize={subTitleFontSize-6} bold />
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default About;