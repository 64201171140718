import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import titleBannerImage from  '../../../img/titleBanner.png'
import ApplyButton from '../../Button/applyButton';
import { useTrail, a } from '@react-spring/web'
import { LetterByLetter } from 'reidea-common';



const TitleBanner: React.FC = () => {

    const { titleFontSize, subTitleFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    // 行間の設定(比率)
    const titleLineHeight = 1.2
    const subTitleLineHeight = 1.4

    // 文字間隔の設定(px)
    const titleLetterSpacing = 6
    const subTitleLetterSpacing = 4

    const imgWidth = isXS ? 260 :
                     isSmall ? 360 :
                     isMedium ? 520 : 
                     isLarge ? 650 : 0
    
    const _isMobile = isSmall || isXS

    const fontStyle = { color:"white", whiteSpace:"pre-wrap", width:"100%", transition: `font-size ${0.15}s ease, -webkit-text-stroke ${0.15}s ease`,}
    const titleFontStyle = {...fontStyle, '-webkit-text-stroke': `${isLarge ? 2.0 : 1.0}px currentColor`,
    opacity: 0,  // 初期状態で透明
    transform: 'translateX(-100px)',  // 左にオフセット
    animation: 'slideIn 2s ease forwards',  // アニメーションの定義
    '@keyframes slideIn': {
        '0%': {
        opacity: 0,
        transform: 'translateX(-100px)',
        },
        '100%': {
        opacity: 1,
        transform: 'translateX(0)',
        },
    }

}
    return (
        !isXS ? 
            <Stack direction={"row"} width={"100%"} minWidth={"100%"} justifyContent={"center"} height={imgWidth+100+"px"} bgcolor={"#383E86"} >
                <Stack width={100+"%"} position={"relative"} direction={"row"} justifyContent={"center"}  top={headerSize}>
                    <Stack position={"relative"} left={_isMobile ? 20 : 40} top={_isMobile ? 20 : headerSize-20} spacing={4}>
                        <Typography sx={{...titleFontStyle}} fontWeight={900} fontSize={titleFontSize} lineHeight={titleLineHeight} letterSpacing={titleLetterSpacing} >
                            {"海外クラファン\n"}
                            <span style={{ fontSize: titleFontSize*2 }}>0</span>円から一括代行
                        </Typography>
                        <Stack width={_isMobile ? "100%" : "80%"} spacing={4}>
                            <Stack>
                                <LetterByLetter text='ページ制作から運営、海外配送まで'
                                    isAnimated={true} delay={100} offsetY={20} style={{ color:"white", fontSize:subTitleFontSize-6, fontWeight:"bold"}} />
                                <LetterByLetter text='一括でサポート・代行いたします'
                                    isAnimated={true} delay={100} offsetY={20} style={{ color:"white", fontSize:subTitleFontSize-6, fontWeight:"bold"}} />
                            </Stack>
                            {/* <Typography sx={{...fontStyle}} fontWeight={600} fontSize={subTitleFontSize-6} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                                {"ページ制作から運営、海外配送まで\n一括でサポート・代行いたします"}
                            </Typography> */}
                            <ApplyButton fontSize={subTitleFontSize-8+"px"} text='無料相談はこちら　 ▶︎'/>
                        </Stack>
                    </Stack>
                    <Stack position={"relative"} left={_isMobile ? 20 : 10} top={-0} width={imgWidth+"px"} sx={{transition:"width 0.15s ease"}}>
                        <Stack component="img" src={titleBannerImage} width={"100%"} sx={{ aspectRatio:"1/1", objectFit:"cover", }}/>
                    </Stack>
                </Stack>
            </Stack>
        :
            
            <Stack pt={5} pb={5} spacing={1.5} direction={"column"} width={"100%"} minWidth={"100%"} justifyContent={"flex-start"} alignItems={"center"} bgcolor={"#383E86"} >
                <Stack component="img" src={titleBannerImage} width={"50%"} sx={{ aspectRatio:"1/1", objectFit:"cover", }}/>
                <Typography textAlign={'center'} sx={{...titleFontStyle}} fontWeight={900} fontSize={titleFontSize*1.2} lineHeight={titleLineHeight} letterSpacing={titleLetterSpacing} >
                    {"海外クラファン\n"}
                    <span style={{ fontSize: titleFontSize*2.4 }}>0</span>円から一括代行
                </Typography>
                <Typography textAlign={"center"} sx={{...fontStyle}} fontWeight={600} fontSize={subTitleFontSize-2} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                    {"ページ制作から運営、海外配送まで\n一括でサポート・代行いたします"}
                </Typography>
                <Stack width={"60%"}>
                    <ApplyButton fontSize={subTitleFontSize-2+"px"} text='無料相談はこちら　 ▶︎'/>
                </Stack>
            </Stack>
    );
};

export default TitleBanner;