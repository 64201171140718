import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import worldImage from  '../../../img/world.png'
import whatsCFImage from  '../../../img/whatsCF.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid} from "reidea-common"

const WhatsCF: React.FC = () => {

    const { titleFontSize, subTitleFontSize,mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const subTitleLineHeight = 1.4
    const subTitleLetterSpacing = 3

    const fontStyle = { color:"white", whiteSpace:"pre-wrap", width:"100%",  }

    const _isMobile = (isXS || isSmall)

    // テキストの設定
    const title : string = isXS ? "海外クラファンとは" : "海外クラウドファンディングとは"
    const subTitle : string = "あらかじめ売れる数を把握してからの海外進出"
    const description : string = _isMobile ? "海外クラウドファンディングでは、在庫を抱える心配がなく、低リスクで受注生産型の販売が可能です。また、実際の海外市場でテストマーケティングを行い、販路を拡大することもできます。"
                                           : "海外クラウドファンディングでは、在庫を抱える心配がなく、低リスクで受注生産型の販売が可能です。また、実際の海外市場でテストマーケティングを行い、販路を拡大することもできます。\n弊社では、日本の事業者様に対して、この海外クラウドファンディングへの挑戦に関わる全てを一括でサポートさせていただいております。"

    
    return (
        <Stack position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} spacing={isXS ? 2 : 4} py={isXS ? 4 : 8} bgcolor={"#383E86"} >
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={worldImage} width={"70%"} height={"100%"} sx={{ aspectRatio:"1/1", objectFit:"cover", color:"white"}}/>
            
            <Stack width={"100%"} zIndex={2}>
                <Typography sx={{...fontStyle, textAlign:"center"}} fontWeight={600} fontSize={subTitleFontSize+5} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                    {title}
                </Typography>
            </Stack>
            <Stack width={contentRatio*100+"%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} zIndex={2}>
                <Stack width={_isMobile ? 100+"%" : 60+"%"} spacing={4} >
                    {!_isMobile &&
                        <Typography sx={{...fontStyle}} fontWeight={600} fontSize={mainFontSize+8} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                            {subTitle}
                        </Typography>
                    }
                    <Typography sx={{...fontStyle}} fontWeight={600} fontSize={mainFontSize+2} lineHeight={subTitleLineHeight} letterSpacing={subTitleLetterSpacing} >
                        {description}
                    </Typography>
                </Stack>
                {!_isMobile &&<Stack component="img" src={whatsCFImage} maxWidth={400} width={"37%"} sx={{ aspectRatio:"1.3/1", objectFit:"cover", }}/>}
            </Stack>
            
        </Stack>
    );
};

export default WhatsCF;