import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../router/router';
import titleBannerImage from  '../../img/titleBanner.png'
import ApplyButton from '../Button/applyButton';
import { LetterByLetter, ObserverStack } from 'reidea-common';


interface Props {
    title:string;
    title_en: string;

    fontSize: number;
}

// コンテストのミニカルーセルを実装
const TitleColumn: React.FC<Props> = (props) => {
    const [isViewed, setIsViewed] = useState<boolean>(false)

    return (
        <ObserverStack isOneTime={true} setObserved={setIsViewed} alignItems={"center"} zIndex={2}>
            <LetterByLetter isAnimated={isViewed} text={props.title} offsetY={-30} 
                delay={50}
                style={{color:"#383E86", fontWeight:800, fontSize:props.fontSize, letterSpacing:2}}  />
            <Typography sx={{
                background: 'linear-gradient(to right, #43cea2, #185a9d)', // グラデーションの色
                WebkitBackgroundClip: 'text',
                color: 'transparent', // 文字色を透明に
            }} fontWeight={200} fontSize={props.fontSize -4}  letterSpacing={1.2} >
                {props.title_en}
            </Typography>
        </ObserverStack>
    );
};

export default TitleColumn;