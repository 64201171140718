import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import service1Image from  '../../../img/service1.png'
import ApplyButton from '../../Button/applyButton';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"
import ServiceCard from '../../Card/serviceCard';
import FlowCard from '../../Card/FlowCard';


// テキストの設定
const title : string = "STEP1"
const subTitle : string = "「無料ご相談」"
const description : string = "クラウドファンディングにおける掲載文は、プロジェクトの第一印象や信頼性、プロジェクトの魅力を効果的に伝え、支援者とのコミュニケーションを促進する上で非常に重要です。"


const Flow: React.FC = () => {

    const { titleFontSize, subTitleFontSize, headerSize, mainFontSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);
    const flows = [
        {
          title: 'STEP1',
          subTitle: '無料ご相談',
          description: isSmall ? '無料相談で海外クラウドファンディングの説明や商品選定のご相談を承ります。気になる方はぜひお問い合わせください。'
                            : '無料相談で海外クラウドファンディングの説明や商品選定のご相談を承ります。気になる方はぜひお問い合わせください。'
        },
        {
          title: 'STEP2',
          subTitle: 'コンテンツ制作',
          description: isSmall ? 'ユーザーに商品の魅力を最大限伝えるために、海外向けの掲載文・動画・画像を専門デザイナーが作成します。'
                            : 'ユーザーに商品の魅力を最大限伝えるために、海外向けの掲載文・動画・画像を専門デザイナーが作成します。'
        },
        {
          title: 'STEP3',
          subTitle: '掲載開始',
          description: isSmall ? 'すべての準備が整い次第、掲載申請を行い、掲載後はユーザーのコメントや質問に丁寧に対応します。'
                            : 'すべての準備が整い次第、掲載申請を行い、掲載後はユーザーのコメントや質問に丁寧に対応します。'
        },
        {
          title: 'STEP4',
          subTitle: '海外配送',
          description: isSmall ? 'クラウドファンディング掲載後は商品の発送が必要です。弊社が海外配送を一括代行します。'
                            : 'クラウドファンディング掲載後は商品の発送が必要です。弊社が海外配送を一括代行します。'
        }
      ];


    const componentHeight = isXS ? 180 :
                            isSmall ? 280 :
                            isMedium ? 240 : 
                            isLarge ? 280 : 0

    const _isMobile = isXS || isSmall;
    const [isViewed, setIsViewed] = useState<boolean>(false)

    return (
        <Stack id={"flow"} direction={"column"} width={"100%"} bgcolor={"#F5FBFE"}  minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <TitleColumn title='一連の流れ' title_en='FLOW' fontSize={subTitleFontSize+5}/>
            <Stack mt={4} width={_isMobile ? "90%" : contentRatio*100+"%"} justifyContent={"center"} >
                <ObserverStack isOneTime={true} setObserved={setIsViewed}>
                    <BaseGrid animationOffsetY={0} enableAnimation={isViewed} cols={isXS ? 1 : isSmall ? 4 : 4} spacingX={1} spacingY={isXS ? 0 : 2} items={
                        flows.map((value:any, index:number) => (
                            <FlowCard displayArrow={!isSmall} direction={isXS ? "column" : "row"} last={index === 3} height={componentHeight} image={""} title={value.title} subTitle={value.subTitle} description={value.description} titleFontSize={subTitleFontSize} contentFontSize={mainFontSize} />
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default Flow;